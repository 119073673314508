(function ($) {
  "use strict";

  // Spinner
  var spinner = function () {
    setTimeout(function () {
      if ($('#spinner').length > 0) {
        $('#spinner').removeClass('show');
      }
    }, 1);
  };
  spinner();

  // Initiate the wowjs
  new WOW().init();

  // Sticky Navbar
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $('.sticky-top').css('top', '0px');
    } else {
      $('.sticky-top').css('top', '-100px');
    }
  });

  // Dropdown on mouse hover
  const $dropdown = $(".dropdown");
  const $dropdownToggle = $(".dropdown-toggle");
  const $dropdownMenu = $(".dropdown-menu");
  const showClass = "show";

  $(window).on("load resize", function() {
    if (this.matchMedia("(min-width: 992px)").matches) {
      $dropdown.hover(
        function() {
          const $this = $(this);
          $this.addClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "true");
          $this.find($dropdownMenu).addClass(showClass);
        },
        function() {
          const $this = $(this);
          $this.removeClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "false");
          $this.find($dropdownMenu).removeClass(showClass);
        }
      );
    } else {
      $dropdown.off("mouseenter mouseleave");
    }
  });

  // Back to top button
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });

  $('.back-to-top').click(function () {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      1500,
      'easeInOutExpo'
    );

    return false;
  });

  // Facts counter
  $('[data-toggle="counter-up"]').counterUp({
    delay: 10,
    time: 2000
  });

  // Header carousel
  $(".header-carousel").owlCarousel({
    autoplay: false,
    mouseDrag: false,
    touchDrag: false,
    smartSpeed: 1500,
    items: 1,
    dots: false,
    loop: true,
    nav : true,
    navText : [
      '<i class="bi bi-chevron-left"></i>',
      '<i class="bi bi-chevron-right"></i>'
    ]
  });

  // Testimonials carousel
  $(".testimonial-carousel").owlCarousel({
    autoplay: false,
    smartSpeed: 1000,
    center: true,
    dots: true,
    loop: true,
    responsive: {
      0:{
        items:1
      },
      768:{
        items:2
      },
      992:{
        items:3
      }
    }
  });

  // Contact form
  const contactForm = document.getElementById('contact-form');

  if (contactForm) {
    contactForm.addEventListener('submit', function (e) {
      const contactAlertBox = document.getElementById('contact-alert-box');
      const contactAlert = document.getElementById('contact-alert');
      const contactSubmitBtn = document.getElementById('contact-submit-btn');
      const contactSubmitSpinner = document.getElementById('contact-spinner');
      const contactSubmitText = document.getElementById('contact-submit-text');

      const name = document.getElementById('name').value;
      const email = document.getElementById('email').value;
      const subject = document.getElementById('subject').value;
      const message = document.getElementById('message').value;

      const prevText = contactSubmitText.innerText;

      contactSubmitBtn.disabled = true;
      contactSubmitText.innerText = 'Sending ...'
      contactSubmitSpinner.classList.remove('hidden');

      $.ajax({
        type: 'post',
        url: API.contact,
        data: JSON.stringify({
          'name': name,
          'email': email,
          'subject': subject,
          'message': message,
        }),
        contentType: 'application/json',
      }).done(function (data) {
        if (data.success) {
          contactAlert.classList.remove('alert-danger');
          contactAlert.classList.add('alert-success');
          contactAlert.innerHTML
            = 'Thank you, your message has been sent!'
          const contactFormWrapper = contactForm.parentNode;
          contactFormWrapper.classList.add('hidden');
        } else {
          contactSubmitBtn.disabled = false;
          contactAlert.classList.add('alert-danger');
          contactAlert.innerHTML = data.error;
        }
        contactAlertBox.classList.remove('hidden');
        contactSubmitText.innerText = prevText;
        contactSubmitSpinner.classList.add('hidden');
      }).fail(function (data) {
        contactSubmitSpinner.classList.add('hidden');
        contactAlert.classList.add('alert-danger');
        contactAlert.innerHTML
          = 'Oops, something went wrong!<br> Please refresh and try again.';
        contactAlertBox.classList.remove('hidden');
        contactSubmitText.innerText = prevText;
        contactSubmitSpinner.classList.add('hidden');
      });

      e.preventDefault();
    });
  }

  // Quote form
  const quoteForm = document.getElementById('quote-form');

  if (quoteForm) {
    quoteForm.addEventListener('submit', function (e) {
      const quoteAlertBox = document.getElementById('quote-alert-box');
      const quoteAlert = document.getElementById('quote-alert');
      const quoteSubmitBtn = document.getElementById('quote-submit-btn');
      const quoteSubmitSpinner = document.getElementById('quote-spinner');
      const quoteSubmitText = document.getElementById('quote-submit-text');

      const service = document.getElementById('service').value;
      const name = document.getElementById('name').value;
      const company = document.getElementById('company').value;
      const email = document.getElementById('email').value;
      const phone = document.getElementById('phone').value;
      const pickupDate = document.getElementById('pickup-date').value;
      const flexibilityCheckbox = document.getElementById('flexibility');
      const city = document.getElementById('city').value;
      const state = document.getElementById('state').value;
      const country = document.getElementById('country').value;
      const comments = document.getElementById('comments').value;

      const prevText = quoteSubmitText.innerText;

      const flexibility = flexibilityCheckbox.checked ? 'yes' : 'no';

      quoteSubmitBtn.disabled = true;
      quoteSubmitText.innerText = 'Submitting ...'
      quoteSubmitSpinner.classList.remove('hidden');

      $.ajax({
        type: 'post',
        url: API.quote,
        data: JSON.stringify({
          'service': service,
          'name': name,
          'company': company,
          'email': email,
          'phone': phone,
          'pickupDate': pickupDate,
          'flexibility': flexibility,
          'city': city,
          'state': state,
          'country': country,
          'comments': comments,
        }),
        contentType: 'application/json',
      }).done(function (data) {
        if (data.success) {
          quoteAlert.classList.remove('alert-danger');
          quoteAlert.classList.add('alert-success');
          quoteAlert.classList.add('py-5');
          quoteAlert.innerHTML
            = 'Thank you, your information has been sent.' +
            '<br>We will get in touch with you as soon as possible!'
          const quoteFormWrapper = quoteForm.parentNode;
          quoteFormWrapper.classList.add('hidden');
        } else {
          quoteSubmitBtn.disabled = false;
          quoteAlert.classList.add('alert-danger');
          quoteAlert.innerHTML = data.error;
        }
        quoteAlertBox.classList.remove('hidden');
        quoteSubmitText.innerText = prevText;
        quoteSubmitSpinner.classList.add('hidden');

        // Scroll to the top where the error should be.
        $([document.documentElement, document.body]).animate({
          scrollTop: ($('#quote-alert-box').offset().top - 100),
        }, 500);
      }).fail(function (data) {
        quoteSubmitSpinner.classList.add('hidden');
        quoteAlert.classList.add('alert-danger');
        quoteAlert.innerHTML
          = 'Oops, something went wrong!<br> Please refresh and try again.';
        quoteAlertBox.classList.remove('hidden');
        quoteSubmitText.innerText = prevText;
        quoteSubmitSpinner.classList.add('hidden');

        // Scroll to the top where the error should be.
        $([document.documentElement, document.body]).animate({
          scrollTop: ($('#quote-alert-box').offset().top - 100),
        }, 500);
      });

      e.preventDefault();
    });
  }

})(jQuery);
